import axios from 'axios'
import { MessageBox, Message, Loading } from 'element-ui'
import router from '../router'

let loading
let loadingRequestCount = 0;
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0,0,0,.7)'
  })
}
function endLoading(flag) {
  if(flag) {
    return
  }
  if(loadingRequestCount <= 0) return
  loadingRequestCount--;
  if(loadingRequestCount === 0) {
    loading.close()
  }
}


// 创建一个axios请求对象
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  baseURL: baseURL,
  timeout: 5000, // 请求超时
})


// 请求拦截
service.interceptors.request.use(config => {
  if(!config.flag) {
    if(loadingRequestCount === 0) {
      startLoading()
      loadingRequestCount++;
    }
  }
  const token = sessionStorage.getItem('token');
  if(token) {
    config.headers['X-Access-Token'] = token
  }
  return config;
}, error => {
  endLoading()
  return Promise.reject(error);
})

// 响应拦截
service.interceptors.response.use(response => {
  endLoading(response.config.flag)
  const res = response.data
  // console.log(res);
  return res;
}, error => {
  endLoading();
  // console.log(error);
  if(error.code == 'ERR_BAD_RESPONSE') {
    MessageBox.confirm(error.response.data.message, {
      confirmButtonText: '确定',
      showCancelButton: false,
      type: 'warning',
      center: true
    }).then(() => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('roleInfo');
      sessionStorage.removeItem('userInfo');
      router.push('/login');
      return 
    }).catch(() => {

    });
  }
  if(error.code == 'ECONNABORTED' && error.message == 'timeout of 5000ms exceeded') {
    Message.error('请求服务失败');
    return
  }
  return Promise.reject(error);
})

export function getAction(url,data) {
  return service({
    url,
    method: 'get',
    params: data
  })
}

export function postAction(url,data) {
  return service({
    url,
    method: 'post',
    data,
  })
}

// export default service


